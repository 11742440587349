<template>
  <div class="wrapper-container">
    <div class="describe-container-box">
      <div class="title">技术赋能方案详情</div>
      <div class="sub-title">技术赋能增长策略</div>
      <div class="large-paragraph-describe">
        全球蛙通过ERP/POS/CRM/APP/小程序及物联网软硬一体化技术产品，帮助零售商构建私域商家小程序（微信·云闪付·支付宝·银行·抖音·快手·视频号）；采用OMS集单技术，为商家进行各大电商平台（美·京·饿·猫·拼·抖·快·视等）的公域订单归集；同时为商家搭建全域全渠道数据中台，让企业充分提高业务经营效率，全域全渠道人效坪效倍增。
      </div>
    </div>
    <!-- <img
      class="large-image-h732"
      src="@/image/retail/img_banner_technology_retail@3x.png"
    /> -->
    <div class="business-container">
      <img
        class="img-top-technology-retail"
        src="@/image/retail/img_top_technology_retail@3x.png"
      />
      <div>
        <img v-for="src in businessImages" :key="src" :src="src" alt="" />
      </div>
    </div>
    <div class="func-module-container">
      <div class="func-title">技术赋能·应用案例</div>
      <div class="func-sub-title">技术驱动美特好超市数智化转型新增长</div>
      <div class="func-describe">
        2015年，全球蛙着手为美特好打造线上业务，到如今已帮助美特好完成了全域全渠道数智化的升级改造，为行业探索出了一套可行的、可靠的零售商数智化升级方案。
      </div>
    </div>
    <div class="large-image-container">
      <div class="func-image-container-technical-power">
        <img v-for="func in funcModules" :src="func" :key="func" />
      </div>
      <img
        class="large-image-h544"
        src="@/image/retail/img_technology_retail@3x.png"
      />
    </div>
    <action-sheet module="新零售技术赋能">
      <template #extra-title>
        <div class="extra-title">相关文章</div>
      </template>
    </action-sheet>
  </div>
</template>

<script>
export default {
  data: () => ({
    funcModules: [
      require("@/image/retail/img_step1_technology_retail@3x.png"),
      require("@/image/retail/img_step2_technology_retail@3x.png"),
      require("@/image/retail/img_step3_technology_retail@3x.png"),
      require("@/image/retail/img_step4_technology_retail@3x.png"),
    ],
    businessImages: [
      require("@/image/retail/img_1_technology_retail@3x.png"),
      require("@/image/retail/img_2_technology_retail@3x.png"),
      require("@/image/retail/img_3_technology_retail@3x.png"),
      require("@/image/retail/img_4_technology_retail@3x.png"),
      require("@/image/retail/img_5_technology_retail@3x.png"),
      require("@/image/retail/img_6_technology_retail@3x.png"),
      require("@/image/retail/img_7_technology_retail@3x.png"),
      require("@/image/retail/img_8_technology_retail@3x.png"),
      require("@/image/retail/img_9_technology_retail@3x.png"),
      require("@/image/retail/img_10_technology_retail@3x.png"),
    ],
  }),
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/solution.common.scss";
.func-module-container {
  padding-bottom: 0 !important;
}
.business-container {
  height: 2066px;
  margin: 0 30px;
  background-color: #f2f5fa;
  border-radius: 16px;
  margin-bottom: 60px;

  .img-top-technology-retail {
    height: 66px;
    margin-bottom: 30px;
  }
  div {
    padding: 0 20px;
    img {
      width: 100%;
      height: 166px;
      margin-bottom: 30px;
    }
    img:last-child {
      margin-bottom: 0;
    }
  }
}
.large-paragraph-describe {
  margin-bottom: 50px !important;
}
</style>
